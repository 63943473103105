
const pollingEl = document.querySelector('.polling');
let isPollDisabled = false;
if (pollingEl && !isPollDisabled) {
  const polVariants = pollingEl.querySelectorAll('.polling__item') || [];
  const arrVariants = Array.from(polVariants);
  arrVariants.forEach((pollEl) => {
    pollEl.onclick = () => {
      if (isPollDisabled) return;
      alert(`You selected ${pollEl.dataset.value} value`);
      isPollDisabled = true;
      pollEl.classList.toggle('polling__item_active');
      arrVariants.forEach((itm) => {
        const percentItem = itm.querySelector('.polling__item-percent');
        const progressItem = itm.querySelector('.polling__item-progress');
        itm.querySelector('.polling__item-checkbox').style.display = 'none';

        // Fetch data from the server and put it in here
        const percent = Math.floor(Math.random() * 101);

        percentItem.style.display = 'block';
        percentItem.innerHTML = `${percent} %`;

        progressItem.style.display = 'block';
        progressItem.style.width = `${percent < 5 ? 5 : percent}%`;
      });

    }
  })
}


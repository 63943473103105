import '../../styles/pages/category.scss'

import '../components/polling';

import Swiper, { Navigation } from 'swiper';

new Swiper('.top-news-section-slider', {
  spaceBetween: 32,
  rewind: true,
  modules: [Navigation],
  navigation: {
    nextEl: '.next-top-news-slider',
    prevEl: '.prev-top-news-slider',
  },
  breakpoints: {
    0: {
      slidesPerView: 'auto',
      spaceBetween: 24
    },
    500: {
      slidesPerView: 'auto',
      spaceBetween: 32
    },
    1024: {
      slidesPerView: 'auto',
      spaceBetween: 32
    }
  }
});

new Swiper('.videos-section-slider', {
  slidesPerView: 'auto',
  spaceBetween: 32,
  modules: [Navigation],
  navigation: {
    nextEl: '.next-videos-slider',
    prevEl: '.prev-videos-slider',
  },
});

new Swiper('.last-news-slider', {
  slidesPerView: 'auto',
  loop: true,
  spaceBetween: 32,
  modules: [Navigation],
  navigation: {
    nextEl: '.last-news-btn-next',
    prevEl: '.last-news-btn-prev',
  },
  // breakpoints: {
  //   0: {
  //     slidesPerView: 'auto',
  //     spaceBetween: 24
  //   },
  //   500: {
  //     slidesPerView: 2,
  //     spaceBetween: 32
  //   },
  //   1024: {
  //     slidesPerView: 3,
  //     spaceBetween: 32
  //   }
  // }
});

new Swiper('.bottom-last-news-slider', {
  slidesPerView: 'auto',
  rewind: true,
  breakpoints: {
    0: {
      slidesPerView: 'auto',
      spaceBetween: 16
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 16,
    },
    1024: {
      slidesPerView: 4,
      spaceBetween: 36.5
    }
  }
});